<template>
  <div>
    <waste-exchange-search></waste-exchange-search>
  </div>
</template>

<script>
import WasteExchangeSearch from "@/components/ecologist/wasteExchange/wasteExchangeSearch";

export default {
  name: "wasteExchangeSearchView",
  components: { WasteExchangeSearch },
};
</script>

<style scoped></style>
