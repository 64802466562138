<template>
  <div>
    <div class="lots-sort">
      <h2>Выставленые лоты</h2>

      <el-divider></el-divider>

      <search-block title="лота">
        <el-row :gutter="10">
          <el-col :span="3">
            <el-select
              v-model="search.type"
              class="m-2"
              placeholder="Тип сделки"
              size="large"
            >
              <el-option
                v-for="item in operations"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-col>
          <el-col :span="6">
            <el-input v-model="search.input" placeholder="Код/наименование отхода или ключевое слово"/>
          </el-col>
          <el-col :span="3">
            <el-date-picker
              style="width: 100%"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="Дата начала"
              v-model="search.dateFrom"
            >
            </el-date-picker>
          </el-col>

          <el-col :span="3">
            <el-date-picker
              style="width: 100%"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="Дата окончания"
              v-model="search.dateTo"
            >
            </el-date-picker>
          </el-col>
          <el-col :span="3">
            <el-input
              v-model="search.priceMin"
              placeholder="Min. цена"
              class="input-waste-code"
            />
          </el-col>
          <el-col :span="3">
            <el-input
              v-model="search.priceMax"
              placeholder="Max. цена"
              class="input-waste-code"
            />
          </el-col>
          <el-col :span="3">
            <el-button type="primary" @click="searchLots()"> Поиск </el-button>
          </el-col>
        </el-row>
      </search-block>

      <el-row :gutter="3">
        <el-col :span="5">
          <h4>Сортировать отходы по</h4>
        </el-col>
        <el-col :span="4">
          <el-select v-model="value" class="m-2" placeholder="Область" size="large">
            <el-option
              v-for="region in regions"
              :key="region.id"
              :label="region.region"
              :value="region.id"
            />
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-select
            v-model="selectPrice"
            class="m-2"
            placeholder="По цене"
            size="large"
            style="width: 240px"
            @change="sortPrice"
          >
            <el-option
              v-for="price in  prices"
              :key="price.id"
              :label="price.price"
              :value="price.id"
            />
          </el-select>
        </el-col>
        <el-col :span="11"></el-col>
      </el-row>
    </div>

    <div class="cards" :key="lot.lotsId" v-for="lot in ListLots" >
      <el-card class="box-card" shadow="hover" v-if="lot.lotsId != null && (selectRegion == lot.oblast || selectRegion == 0)">
        <template #header>
          <div class="card-header">
            <el-col span="3">
                <div class="card-header-lot">Лот {{ lot.lotsId }}</div>
            </el-col>
            <el-col span="7">
                <div>
                    <b>{{ delivery.find((obj) => obj.id == lot.disposal).label }}</b>
                </div>
            </el-col>
            <el-col span="11">
              <span class="card-header-lot"
                >Сбор предложений: {{ (10 > new Date(lot.date_from).getDate()  ? '0':'')+new Date(lot.date_from).getDate()+'.'+(9>new Date(lot.date_from).getMonth() ? '0':'' )+(new Date(lot.date_from).getMonth()+1)+'.'+new Date(lot.date_from).getFullYear() }} &ndash;
                {{ (10 > new Date(lot.date_to).getDate()  ? '0':'')+new Date(lot.date_to).getDate()+'.'+(9>new Date(lot.date_to).getMonth() ? '0':'' )+(new Date(lot.date_to).getMonth()+1)+'.'+new Date(lot.date_to).getFullYear() }}</span
              >
            </el-col>
          </div>
        </template>
        <div class="card">
          <div class="card-left">
            <div class="card-left-company"></div>
            <div class="card-left-location">{{ lot.adress }}</div>
          </div>
          <div class="card-left-waste-info">
            <div class="card-left-waste-info-name">
              {{ (lot.waste_code==0?"":(lot.waste_code + " - ")) + lot.waste_name }}
            </div>
          </div>
          <div class="card-left-waste-quantity">
            <!-- <div class="card-left-waste-quantity-all-waste">
              {{
                lot.price_min +
                "/" +
                (lot.mass_max != 0
                  ? pss93UnitsInfoList.find((obj) => obj.id === lot.mass_max)
                      .name
                  : "Nan") +
                " - " +
                lot.price_max +
                "/" +
                (lot.mass_max != 0
                  ? pss93UnitsInfoList.find((obj) => obj.id === lot.mass_max)
                      .name
                  : "Nan")
              }}
            </div> -->
            <div class="card-left-waste-quantity-all-waste">
              <div class="card-left-waste-quantity-all-waste-name">
                Общая масса отхода:
              </div>
                <div>
                  {{
                    lot.mass_min +
                    " " +
                    (lot.mass_max != 0
                      ? pss93UnitsInfoList.find(
                          (obj) => obj.id === lot.mass_max
                        ).name
                      : "Nan")
                  }}
                </div>
            </div>
            <div class="card-left-waste-quantity-cost" v-if="(lot.price_min+lot.price_max)!=0">
              <div class="card-left-waste-quantity-cost-name">
                Стоимость за тонну:
              </div>
              <div>
                <div>
                  <span>мин</span>
                  {{ lot.price_min }} BYN/{{
                    lot.mass_max != 0
                      ? pss93UnitsInfoList.find((obj) => obj.id === lot.mass_max)
                          .name
                      : "Nan"
                  }}
                </div>
                <div>
                  <span>макс</span>
                  {{ lot.price_max }} BYN/{{
                    lot.mass_max != 0
                      ? pss93UnitsInfoList.find((obj) => obj.id === lot.mass_max)
                          .name
                      : "Nan"
                  }}
                </div>
              </div>
            </div>

            <div class="card-left-waste-quantity-total" v-if="(lot.price_min+lot.price_max)!=0">
              <div class="card-left-waste-quantity-total-descr">Итого:</div>
              <div class="card-left-waste-quantity-total-price">
                {{
                  Math.round(lot.price_min * lot.mass_min*1000)/1000 +
                    " - " +
                  Math.round(lot.price_max * lot.mass_min*1000)/1000
                }}
              </div>
              <div class="card-left-waste-quantity-total-currency">BYN</div>
              <div class="card-left-waste-quantity-total-tax">
                {{ lot.nds == true ? "С НДС" : "Без НДС" }}
              </div>
            </div>
            <div>
              

              <!-- {{ delivery.find((obj) => obj.id === lot.disposal) }}
                {{ delivery }} -->
            </div>
          </div>
          <div>
            <button class="accordion" @click="openAddOffer()">
              Предложить свою цену
            </button>
            <div class="panel">
              <input
                class="inputMass"
                v-model="lot.inputMass"
                placeholder="Введите необходимую массу отхода"
              />
              {{
                    lot.mass_max != 0
                      ? pss93UnitsInfoList.find((obj) => obj.id === lot.mass_max)
                          .name
                      : "Nan"
                  }}
              <input
                v-model="lot.inputCost"
                placeholder="Введите свою цену"
                class="inputCost"
              />
              BYN
              <input
                v-model="lot.composition"
                placeholder="Состав"
                class="inputCost"
              />
              <input
                v-model="lot.text2"
                placeholder="Примечание"
                class="inputCost"
              />
              <el-button type="warning" @click="addBid(lot)">Отправить предложение</el-button>
            </div>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import SearchBlock from "@/components/searchBlock";
export default {
  name: "wasteExchangeSearch",
  components: { SearchBlock },
  data() {
    return {
      selectRegion: 0,
      selectPrice:"Все цены",
      wasteNames: [],
      ss01CodeList: [],
      input: null,
      value: null,
      search: {
        type: null,
        waste: null,
        dateFrom: null,
        dateTo: null,
        name: null,
        code: null,
        priceMin: null,
        priceMax: null,

      },
      ListLots: [
        {
          adress: null,
          date_delet: null,
          date_from: null,
          date_to: null,
          delet: false,
          disposal: null,
          done: false,
          lotsId: null,
          mass_max: null,
          mass_min: null,
          nds: false,
          price_max: null,
          price_min: null,
          reason_delet: null,
          text: null,
          user_id: null,
          waste_code: null,
          waste_name: null,
          composition:null,
        },
      ],
      pss93UnitsInfoList: [{ id: null, name: null }],
      delivery: [
        {
          id: 1,
          label: "транспортом производителя",
        },
        {
          id: 2,
          label: "транспортом переработчика",
        },
        {
          id: 3,
          label: "транспортом иной организации",
        },
      ],
      type: [
        {
          id: 1,
          label: "Покупатель",
        },
        {
          id: 2,
          label: "Продавец",
        },
      ],
      units: [
        {
          value: "Option1",
          label: "тонн",
        },
        {
          value: "Option2",
          label: "штук",
        },
      ],
      operations: [
        {
          value: 1,
          label: "Продажа",
        },
        {
          value: 2,
          label: "Покупка",
        },
      ],
      regions: [
        {id: 0, region: 'Все области' },
        { id: 1, region: 'Брестская область' },
        { id: 2, region: 'Витебская область' },
        { id: 3, region: 'Гомельская область' },
        { id: 4, region: 'Гродненская область' },
        { id: 5, region: 'Минская область' },
        { id: 6, region: 'Могилёвская область' },
        { id: 7, region: 'Минск' },
      ],
      prices:[
        {id: 0, price: 'Все цены '},
        {id: 1, price: 'По возрастанию '},
        {id: 2, price: 'По убыванию'}
      ],
    };
  },
  computed: {
    ...mapGetters({
      showModal: "GETSHOWMODAL",
      ss01CodeList0: "GETSSS01CODELIST",
      wasteNames0: "GETWASTENAMES",
    }),
  },
  methods: {
    sortPrice()
    {
      if (this.selectPrice==2)
      this.ListLots=this.ListLots.sort(function (a, b) {
      if (a.price_min > b.price_min) {
        return -1;
      }
      if (a.price_min < b.price_min) {
        return 1;
      }
  // a должно быть равным b
      return 0;
      });
      if (this.selectPrice==1)
      this.ListLots=this.ListLots.sort(function (a, b) {
      if (a.price_max > b.price_max) {
        return 1;
      }
      if (a.price_max < b.price_max) {
        return -1;
      }
  // a должно быть равным b
      return 0;
      });
    },
    async searchLots() {
      let set={type: this.search.type,
        waste: this.search.input,
        dateFrom: this.search.dateFrom,
        dateTo: this.search.dateTo,
        name: this.search.name,
        code: this.search.code,
        priceMin: this.search.priceMin,
        priceMax: this.search.priceMax,};
      if (set.priceMin!=null)
        set.priceMin = parseFloat((set.priceMin+'').replace(",", "."));
      if (set.priceMax!=null)
        set.priceMax = parseFloat((set.priceMax+'').replace(",", "."));
      await this.$store.dispatch("searchLots", set).then((response) => {
        this.ListLots = response.data;
        console.log(this.ListLots);
      });
    },
    async searchCode(query) {
      await this.$store.dispatch("getCodeList", query);
      this.ss01CodeList = this.ss01CodeList0;
      this.wasteNames = [];
      this.ss01CodeList0.forEach((ss01Code) => {
        this.wasteNames.push({ id: ss01Code.id, name: ss01Code.name2 });
      });
    },
    addBid(lot)
    {
      let Bid = {lots_id:lot.lotsId,user_id:null,mass:parseFloat(lot.inputMass.replace(",", ".")),price:(lot.price_min+lot.price_max)==0?0:parseFloat(lot.inputCost.replace(",", ".")),done:null,
        cansel:null,Lots:null,text:lot.text2,composition:lot.composition,
        adress:null,
        oblast:null};
      this.$store.dispatch("addBid", Bid).then(() => {
        this.notification("Успешно", "Заявка отправлена", "success");
        lot.inputMass=null;
        lot.inputCost=null;
      });
    },
    async searchWasteName(query) {
      await this.$store.dispatch("getWasteNames", query);
      this.wasteNames = this.wasteNames0;
      this.ss01CodeList = [];
      this.ss01CodeList.forEach((wasteName) => {
        this.wasteNames.push({ id: wasteName.id, name: wasteName.name2 });
      });
    },
    showAddModal() {
      this.$store.dispatch("setShowModal", "add");
    },
    async getSubClassifierById(id) {
      let data = {
        id: id,
        size: 100,
        page: 0,
      };
      return await this.$store.dispatch(
        "getAllDataSubClassifierForSelect",
        data
      );
    },

    openAddOffer() {
      let acc = document.querySelectorAll(".accordion");
      let i;
      for (i = 0; i < acc.length; i++) {
        acc[i].addEventListener("click", function () {
          this.classList.toggle("active");
          var panel = this.nextElementSibling;
          if (panel.style.maxHeight) {
            panel.style.maxHeight = null;
          } else {
            panel.style.maxHeight = panel.scrollHeight + "px";
          }
        });
      }
    },
  },

  mounted() {
    /*this.$store.dispatch("getMainLots").then((response) => {
      this.ListLots = response.data;
      console.log(this.ListLots);
    });*/
    this.getSubClassifierById(93).then((response) => {
      this.pss93UnitsInfoList = response.data.content;
    });
  },
};
</script>

<style scoped>
h4
{
  margin-block-start: 0.7em;
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card-header span {
  font-weight: 400;
  font-weight: bold;
  text-decoration: underline;
}
.card-header-lot {
  font-weight: 700;
}
.box-card {
  width: 100%;
  cursor: pointer;
}

/* .card {
  display: flex;
  justify-content: space-between;
  align-items: center;
} */
.card-left {
  width: 80vw;
}
.card-right {
  width: 20vw;
}
.card-left-company-info {
  width: 40vw;
  display: flex;
  justify-content: space-between;
}
.card-left-waste-info-name,
.card-left-waste-quantity-total-descr {
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-right: 10px;
}
.card-left-waste-info-name {
  padding-top: 20px;
  padding-bottom: 20px;
}
.card-left {
  display: flex;
}
.card-left-company {
  color: #000;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-right: 20px;
}
.card-left-location,
.card-left-waste-quantity-all-waste,
.card-left-waste-quantity-cost {
  color: #a5a5a5;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.card-left-waste-quantity-all-waste,
.card-left-waste-quantity-cost {
  display: flex;
  align-items: center;
}
.card-left-waste-quantity-all-waste-name,
.card-left-waste-quantity-cost-name {
  color: black;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-right: 5px;
}
.card-left-waste-quantity {
  width: 70vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card-left-waste-quantity-total {
  display: flex;
  align-items: center;
}
.card-left-waste-quantity-total-price,
.card-left-waste-quantity-total-currency,
.card-left-waste-quantity-total-tax {
  color: #a5a5a5;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-right: 5px;
}
.cards {
  margin-bottom: 20px;
  margin-top: 20px;
}
.add-offer {
  display: flex;
  justify-content: center;
}
.offer-card {
  width: 60vw;
  cursor: pointer;
}
/* стили за для карточки */
.input-waste-name {
  width: 30vw;
  margin-right: 10px;
}
.date {
  width: 20vw;
  margin-right: 10px;
}
.input-waste-code {
  width: 10vw;
  margin-right: 10px;
}

.off-price {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.off-price-checkbox {
  display: flex;
  flex-direction: column;
}
.date {
  width: 50vw;
  display: flex;
  align-items: center;
}
.dateIn {
  margin-bottom: 10px;

  margin-right: 10px;
}
.input-waste-compound {
  width: 50vw;
}
.delivery {
  width: 50vw;
}
.contact {
  display: flex;
  align-items: center;
}
.contact p {
  font-weight: bold;
  margin-right: 5px;
}
.public-offer {
  display: flex;
  justify-content: center;
}
.el-dropdown-menu__item {
  margin-top: 10px;
  /* width: 100%; */
}
.el-dropdown-menu {
  width: 25vw;
}

.accordion {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 20%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
  margin-top: 10px;
  border-radius: 5px;
}

.active,
.accordion:hover {
  background-color: #ccc;
}

.accordion:after {
  content: "\002B";
  color: #777;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}

.active:after {
  content: "\2212";
}

.panel {
  padding: 0 18px;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.inputMass,
.inputCost {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 20vw;
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
